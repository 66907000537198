<template>
    <div data-cy="menu_left_div" class="sidebar-menu modal-row" @mouseover="openSidebar()" @mouseleave="closeSidebar()">
        <div class="sidebar-menu" style="direction:ltr;">
            <ul>
                <li id="Home" :class="setMenu('Home')">
                    <router-link to="/">
                        <i class="icon-devices_other"/>
                        <span class="menu-text">{{ t('NAV.HOME') }}</span>
                    </router-link>
                </li>
                <li id="DashboardAssing" :class="setMenu('DashboardAssing')" v-if="validatePermissions('ConfigClickSign:index') && hasConfigClickSign">
                    <a @click="openClickSign" class="pointer">
                        <i class="icon-border_color"></i>
                        <span class="menu-text">{{ t('NAV.ASSIGN') }}</span>
                    </a>
                </li>
                <li>
                    <a data-cy="menu_left_settings" href="#configSubmenu" data-toggle="collapse" aria-expanded="false"
                       class="dropdown-toggle">
                        <i class="icon-settings1"></i>
                        <span class="menu-text">{{ t('NAV.CONFIG') }}</span>
                    </a>
                    <ul class="collapse list-unstyled" id="configSubmenu">
                        <li v-if="validatePermissions('ConfigClickSign:index') && hasConfigClickSign" id="clickSign" :class="setMenu('indexConfigClickSign')">
                            <router-link to="/configuracao-assinatura-eletronica" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.CLICK_SIGN') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasReadjustmentIndex" id="public_area" :class="setMenu('indexConfigPublicArea')">
                            <router-link to="/configuracao-area-publica/index" :style="dropdown">
                                <span class="menu-text">{{ t('CONFIG_PUBLIC_AREA.PUBLIC_AREA') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasReadjustmentIndex" id="calculadora" :class="setMenu('indexConfigSimulator')">
                            <router-link to="/configuracao-calculadora-financiamento/index" :style="dropdown">
                                <span class="menu-text">{{ t('CONFIG_SIMULATOR.SIMULATOR_CONFIG_RESUMED') }}</span>
                            </router-link>
                        </li>
                        <li id="Company" :class="setMenu('Company')">
                            <router-link to="/companhia" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.COMPANY') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasUser" id="IndexResponsible" :class="setMenu('IndexResponsible')">
                            <router-link to="/responsavel" :style="dropdown">
                                <span class="menu-text">Encarregados</span>
                            </router-link>
                        </li>
                        <li v-if="hasPaymentMethod" id="IndexMaritalStatuses" :class="setMenu('IndexMaritalStatuses')">
                            <router-link to="/estadosCivis" :style="dropdown">
                                <span class="menu-text"
                                      data-cy="menucompenent_marital_statuses">{{ t('NAV.MARITAL_STATUSES') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasPaymentMethod" id="IndexPaymentMethods" :class="setMenu('IndexPaymentMethods')">
                            <router-link to="/formas-pagamentos" :style="dropdown">
                                <span class="menu-text"
                                      data-cy="menucompenent_payment_methods">{{ t('NAV.PAYMENT_METHODS') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasReadjustmentIndex" id="groups" :class="setMenu('indexPublicGroup')">
                            <router-link to="/grupos-publicos" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.GROUPS') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasPermissionShowPanel()" id="PainelAdministrativo"
                            :class="setMenu('PainelAdministrativo')">
                            <router-link to="/painel-administrativo"
                                         :style="dropdown">
                                <span class="menu-text"
                                      data-cy="menucompenent_payment_methods">{{ t('PANEL.INDEX_TITLE') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasReadjustmentIndex" id="indexAttributesValidations"
                            :class="setMenu('indexAttributesValidations')">
                            <router-link to="/camposObrigatorios" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.ATTRIBUTES_VALIDATION') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasReadjustmentIndex" id="IndexReajustes" :class="setMenu('IndexReajustes')">
                            <router-link to="/reajustes" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.READJUSTMENT') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasSituation" id="IndexSituation" :class="setMenu('IndexSituation')">
                            <router-link to="/situacao" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.SITUATIONS') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasReadjustmentIndex" id="IndexTemplates" :class="setMenu('IndexTemplates')">
                            <router-link to="/templates/proposta" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.TEMPLATES') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasReadjustmentIndex" id="IndexDriveFileTypes"
                            :class="setMenu('IndexDriveFileTypes')">
                            <router-link to="/tipos-de-anexo" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.DRIVE_FILE_TYPE') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasPropertiesType" id="IndexPropertyTypes" :class="setMenu('IndexPropertyTypes')">
                            <router-link to="/tipo-propriedade" :style="dropdown">
                                <span class="menu-text"
                                      data-cy="menucompenent_property_types">{{ t('NAV.PROPERTY_TYPES') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasReserveType" id="IndexReserveTypes" :class="setMenu('IndexReserveTypes')">
                            <router-link to="/tipo-reserva" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.RESERVE_TYPES') }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasUser" id="IndexUser" :class="setMenu('IndexUser')">
                            <router-link to="/usuario" :style="dropdown">
                                <span class="menu-text">{{ t('NAV.USER') }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li id="IndexCommission" :class="setMenu('IndexCommission')">
                    <router-link to="/comissoes">
                        <i class="icon-attach_money"/>
                        <span class="menu-text">{{ t('NAV.COMMISSION') }}</span>
                    </router-link>
                </li>
                <li v-if="hasAllotment" id="IndexAllotmant" :class="setMenu('IndexAllotmant')">
                    <router-link to="/loteamento">
                        <i class="icon-grid"/>
                        <span class="menu-text">{{ t('NAV.ALLOTMENT') }}</span>
                    </router-link>
                </li>
                <li v-if="hasLot && hasAllotments" id="IndexLotes" :class="setMenu('IndexLotes')">
                    <router-link to="/lote">
                        <i class="icon-map2"/>
                        <span class="menu-text">{{ t('NAV.LOT') }}</span>
                    </router-link>
                </li>
                <li v-if="hasClient" id="IndexClient" :class="setMenu('IndexClient')">
                    <router-link to="/cliente">
                        <i class="icon-users"/>
                        <span class="menu-text">{{ t('NAV.CLIENTS') }}</span>
                    </router-link>
                </li>
                <li v-if="hasClient" id="IndexProposal" :class="setMenu('IndexProposal')">
                    <router-link to="/propostas">
                        <i class="icon-file-text"/>
                        <span class="menu-text">{{ t('NAV.PROPOSAL') }}</span>
                    </router-link>
                </li>
                <li v-if="hasReserve && hasAllotments" id="IndexReserve" :class="setMenu('IndexReserve')">
                    <router-link to="/reserva">
                        <i class="icon-lock-open"/>
                        <span class="menu-text">{{ t('NAV.RESERVE') }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div style="position: absolute; top: 95.5%" class="d-flex flex-row-reverse align-items-end mr-5" v-if="!pinned">
            <p style="color: #8f9394"><i class="icon-calendar1 mr-2"></i>{{ this.dateNow }}</p>
        </div>
    </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import jwt_decode from 'jwt-decode';
import Validate from '../validate.vue';
import {mapState, mapActions} from 'vuex';
import Permission from '../permission.vue';
import ConfigClickSign from '../../services/ConfigClickSign';

export default {
    name: 'MenuComponent',

    props: ['companyId'],

    mixins: [Permission, Validate],

    setup() {
        const {t} = useI18n();
        return {t};
    },

    data() {
        return {
            hasConfig: false,
            hasLot: false,
            hasUser: false,
            hasClient: false,
            dateTime: '',
            hasReserve: false,
            hasAllotment: false,
            hasSituation: false,
            hasReserveType: false,
            hasPropertiesType: false,
            hasPaymentMethod: false,
            hasSaleIndex: false,
            hasReadjustmentIndex: false,
            dropdown: {
                padding: '9px 35px 9px 42px',
                'font-size': '.775rem',
            },
        };
    },

    computed: {
        ...mapState({
            pinned: 'pinned',
            dateNow: 'dateNow',
            toggled: 'toggled',
            hasAllotments: 'hasAllotments',
            hasConfigClickSign: 'hasConfigClickSign'
        }),
    },

    mounted() {
        if (this.companyId) {
            this.indexMenu();
            this.$store.dispatch('ActionCheckAllotments', localStorage.getItem('companyId'));
        }
        this.setDateTimeClock();
        this.toMenu(this.$route.name);
    },

    watch: {
        companyId: function () {
            this.indexMenu();
        },
    },

    methods: {
        ...mapActions(['ActionDateNow']),
        openClickSign(){
            this.$store.commit('changeLoading', true);
            ConfigClickSign.getRedirectAssign().then(resp => {
                window.open(`${process.env.VUE_APP_CLICK_SIGN_FRONT_HOST}/loginDisponibilidade/${resp.data}`,'blank');
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        },
        setDateTimeClock() {
            this.ActionDateNow();
        },
        hasPermissionShowPanel() {
            let token = jwt_decode(localStorage.getItem('access_token'));
            return token.email.includes('@r2soft.com.br');
        },
        refreshTime() {
            let dateString = new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'});
            dateString = dateString.split('/');
            this.dateTime = `${dateString[0]} - ${dateString[1]} - ${dateString[2]}`;
        },
        indexMenu() {
            this.hasLot = this.validatePermissions('Lot:index');
            this.hasUser = this.validatePermissions('User:index');
            this.hasClient = this.validatePermissions('Client:index');
            this.hasReserve = this.validatePermissions('Reserve:index');
            this.hasAllotment = this.validatePermissions('Allotment:index');
            this.hasReserveType = this.validatePermissions('ReserveType:update');
            this.hasSituation = this.validatePermissions('PropertiesStatus:update');
            this.hasPropertiesType = this.validatePermissions('PropertiesType:update');
            this.hasPaymentMethod = this.validatePermissions('PaymentMethod:update');
            this.hasSaleIndex = this.validatePermissions('Sale:index');
            this.hasReadjustmentIndex = this.validatePermissions('Readjustment:property');
        },

        setMenu(name) {
            return {'active-page-link': this.$route.name === name};
        },

        toMenu(routerName) {
            this.$store.commit('changeLoading', true);

            switch (routerName) {
                case 'IndexLotes':
                    if (this.hasAllotments === false) {
                        this.$router.push({name: 'Home'});
                    }
                    this.$store.commit('changeLoading', false);
                    break;

                case 'IndexReserve':
                    if (this.hasAllotments === false) {
                        this.$router.push({name: 'Home'});
                    }
                    this.$store.commit('changeLoading', false);

                    break;
                default:
                    this.$store.commit('changeLoading', false);
            }
        },

        openSidebar() {
            if (this.pinned) {
                document.getElementById('wrapper').classList.add('sidebar-hovered');
            }
        },

        closeSidebar() {
            if (this.pinned) {
                document.getElementById('configSubmenu').classList.remove('show');
                document.getElementById('wrapper').classList.remove('sidebar-hovered');
            }
        },
    },
};
</script>
<style lang="scss">
.modal-row {
    max-height: 100% !important;
    overflow-y: hidden;
    direction: rtl;
}

.modal-row::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}

.modal-row::-webkit-scrollbar-thumb {
    background: #212529;
    border-radius: 10px;
}
</style>
